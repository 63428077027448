html,
body {
  padding: 0;
  margin: 0;
  font-family: "Ubuntu", sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

* {
  box-sizing: border-box;
}

*:focus {
  box-shadow: none !important;
}
